import React, { useEffect } from "react";
import "./styles.scss";
import "./subpage.scss";

export default function Trade() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://kit.fontawesome.com/d6629c7777.js";
    script.crossOrigin = "anonymous";

    document.body.appendChild(script);
  }, []);

  return (
    <div className='subpage-container'>
      <a className='home-button' href='/'>
        Home
      </a>
      <h1 className='subpage-header'>Trade Coffee</h1>
      <div>
        <img
          className='first-picture'
          src={require("./img/collections2.png")}
        ></img>
      </div>
      <div className='text-container'>
        <p>
          When I joined Trade, we had an abundance of product resources while
          being severely constrained on the engineering side. This pushed me to
          teach myself to code so that I could help move the business forward.
        </p>
        <p>
          One of the first PRs I released was a simple A/B experiment that had
          been sitting in the backlog for a while but never reached the top of
          the to-do list. I was ecstatic when the experiment went on to
          <span> increase conversion by 12%.</span>
        </p>
        <p>
          Outside of directly contributing code, I’ve leveraged the knowledge
          I’ve built as a Product Manager alongside my technical skillset to
          help break down tickets more effectively for the team. This has led to{" "}
          <span> increasing velocity by 75%</span> and deeper conversations on
          tradeoffs for larger projects.
        </p>
        <p>
          In June and July, I contributed nearly{" "}
          <span>2,000 lines of code </span>
          resulting in my team's tech lead stating that I’m performing at a
          level comparable to a Software Engineer II despite also balancing
          product management responsibilities.
        </p>
      </div>
    </div>
  );
}
