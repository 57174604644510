import React, { useEffect } from "react";
import "./styles.scss";
import htmlSvg from "./img/html.svg";
import cssSvg from "./img/css.svg";
import githubSvg from "./img/github.svg";
import linkedInSvg from "./img/linkedin.svg";
import javascriptSvg from "./img/javascript.svg";
import nestJsSvg from "./img/nestjs.svg";
import phpSvg from "./img/php.svg";
import reactSvg from "./img/react.svg";
import saasSvg from "./img/saas.svg";
import typeScriptSvg from "./img/typescript.svg";
import nodeSvg from "./img/nodejs.svg";
import mySqlSvg from "./img/mysql.svg";

function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://kit.fontawesome.com/d6629c7777.js";
    script.crossOrigin = "anonymous";

    document.body.appendChild(script);
  }, []);

  return (
    <div className='App'>
      {/* <nav className='nav-container'>This is my nav</nav> */}
      <header className='header-container'>
        <div className='header-picture-container'>
          <img
            className='header-picture'
            src={require("./img/davideverett.jpeg")}
          ></img>
        </div>
        <div className='header-text'>
          <h1 className='header-subtext'>Hi! 👋 My name is</h1>
          <h2 className='header-name'>David Everett</h2>
          <p>
            Senior Product Manager,{" "}
            <span className='monnlight'>moonlighting</span> Software Engineer
          </p>
          <div className='header-actions'>
            <button>
              <a
                href={require("./img/David Everett Resume.pdf")}
                download='David Everett Resumé'
              >
                Resumé
              </a>
            </button>
            <ul>
              <li>
                <a
                  href='https://www.linkedin.com/in/davidweverett/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-linkedin'></i>
                </a>
              </li>
              <li>
                <a
                  href='https://github.com/david-everett'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fab fa-github'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <body>
        <div className='skills-container'>
          <h2 className='section-header'>Skills</h2>
          <div className='skills-box'>
            <ul>
              <li>
                <img src={htmlSvg} className='skill-icon' />
                HTML
              </li>
              <li>
                <img src={cssSvg} className='skill-icon' />
                CSS
              </li>
              <li>
                <img src={saasSvg} className='skill-icon' />
                SaaS
              </li>
              <li>
                <img src={javascriptSvg} className='skill-icon' />
                JavaScript
              </li>
              <li>
                <img src={typeScriptSvg} className='skill-icon' />
                TypeScript
              </li>
              <li>
                <img src={reactSvg} className='skill-icon' />
                React
              </li>
              <li>
                <img src={nodeSvg} className='skill-icon' />
                NodeJS
              </li>
              <li>
                <img src={nestJsSvg} className='skill-icon' />
                NestJS
              </li>
              <li>
                <img src={phpSvg} className='skill-icon' />
                PHP
              </li>
              <li>
                <img src={mySqlSvg} className='skill-icon' />
                MySQL
              </li>
            </ul>
          </div>
        </div>
        <h2 className='section-header'>Projects</h2>
        <div className='projects-container'>
          <a className='project-card' href='/trade'>
            <div className='project-image trade-background'>
              <h2>Trade</h2>
            </div>
            <div className='project-text'>
              <h3>Trade Coffee</h3>
              <p>
                Contributing Software Engineer, pushing 75 PRs in two months
              </p>
              <ul className='project-skills'>
                <li>React.js</li>
                <li>HTML</li>
                <li>SaaS</li>
                <li>Express</li>
              </ul>
            </div>
          </a>

          <a className='project-card' href='/cdl'>
            <div className='project-image'>
              <div className='test-container'>
                <img
                  className='center-image'
                  src={require("./img/Call_of_Duty_League_Logo.png")}
                ></img>
              </div>
            </div>
            <div className='project-text'>
              <h3>CDL Stat Tracker 🏗</h3>
              <p>Elevating e-sports by using data to create context</p>
              <ul className='project-skills'>
                <li>TypeScript</li>
                <li>React.js</li>
                <li>Node.js</li>
                <li>Nest.js</li>
                <li>TypeORM</li>
              </ul>
            </div>
          </a>

          <a
            className='project-card'
            href='https://blueshirtguesser.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='project-image'>
              <div className='test-container'>
                <img
                  className='center-image'
                  src={require("./img/nyr.png")}
                ></img>
              </div>
            </div>
            <div className='project-text'>
              <h3>Blueshirt Guesser</h3>
              <p>Wordle inspired game for Ranger fans</p>
              <ul className='project-skills'>
                <li>PHP</li>
                <li>mySQL</li>
                <li>HTML</li>
                <li>CSS</li>
              </ul>
            </div>
          </a>

          <a href='/flosports' className='project-card'>
            <div className='project-image'>
              <div className='test-container'>
                <img
                  className='center-image'
                  src={require("./img/flosports.png")}
                ></img>
              </div>
            </div>
            <div className='project-text'>
              <h3>FloSports</h3>
              <p>
                Automating results &#38; rankings utilizing existing systems
              </p>
              <ul className='project-skills'>
                <li>JavaScript</li>
                <li>CSS</li>
              </ul>
            </div>
          </a>
        </div>
        <h2 className='section-header'>Product Case Studies</h2>
        <div className='case-study-container'>
          <div className='case-study-card'>
            <div className='case-study-card__image'>
              <img
                className='center-image'
                src={require("./img/mslive.png")}
              ></img>
            </div>
            <div className='case-study-card__text'>
              <h2>MileSplit Live</h2>
              <ul>
                <li>
                  Made business case to the CEO to acquire a product and
                  initiated negotiations
                </li>
                <li>
                  When the deal fell through, created a B2B and B2C product from
                  scratch
                </li>
                <li>
                  In the two years since launching, it has gone on to receive{" "}
                  <strong>7M page views per month</strong>
                </li>
              </ul>
            </div>
          </div>

          <div className='case-study-card'>
            <div className='case-study-card__image'>
              <img
                className='center-image'
                src={require("./img/athletesrankings2.png")}
              ></img>
            </div>
            <div className='case-study-card__text'>
              <h2>Athlete Rankings</h2>
              <ul>
                <li>
                  Through user testing, found that users loved rankings feature
                </li>
                <li>
                  Data analysis backed this up with higher conversion ratings
                  than other features, but lower traffic
                </li>
                <li>
                  By contextualizing rankings information for more visited
                  pages, we{" "}
                  <strong>increased average revenue per visitor by 27%</strong>
                </li>
              </ul>
            </div>
          </div>

          <div className='case-study-card'>
            <div className='case-study-card__image'>
              <img
                className='center-image'
                src={require("./img/floseo2.png")}
              ></img>
            </div>
            <div className='case-study-card__text'>
              <h2>SEO - Knowledge Panels</h2>
              <ul>
                <li>
                  Identified that search volume for jiu-jitsu athletes was an
                  under addressed opportunity for FloSports
                </li>
                <li>
                  Utilized WikiData to create knowledge panels for jiu-jitsu
                  athletes and included IDs and info so that FloGrappling would
                  show in the knowledge panel
                </li>
                <li>
                  Alongside adding content, this propelled these pages up the
                  rankigns and now receive
                  <strong> 6M impressions </strong> for athletes, up over 100%
                </li>
              </ul>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
}

export default App;
