import React from "react";
import "./styles.scss";
import "./subpage.scss";

export default function CDL() {
  return (
    <div className='subpage-container'>
      <a className='home-button' href='/'>
        Home
      </a>
      <h1 className='subpage-header'>Call of Duty League</h1>
      <div className='text-container'>
        <h2>Problem</h2>
        <p>
          As a fan of the Call of Duty esports league, I was surprised by the
          ecosystem lacking key components that regular sports leagues enjoy.
          Finding information about your favorite athlete or team is not
          possible on the official website, something that has been standard for
          professional and collegiate sports for years.
        </p>
        <h2>Solution</h2>
        <p>
          Create a relational database and surface data in ways that fans expect
        </p>
        <h2>Current Progress</h2>
        <ul>
          <li>
            Created Postgres relational database with API endpoints using Node
            and NestJS
          </li>
          <li>Created React admin frontend that connects to the APIs</li>
        </ul>
        <img
          className='second-picture'
          src={require("./img/swagger.png")}
        ></img>
        <h2>Code</h2>
        <ul>
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/david-everett/CODStats'
            >
              Front-End
            </a>
          </li>
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/david-everett/COD-Stats-API'
            >
              Back-End
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
