import React from "react";
import "./styles.scss";
import "./subpage.scss";

export default function FloSports() {
  return (
    <div className='subpage-container'>
      <a className='home-button' href='/'>
        Home
      </a>
      <h1 className='subpage-header'>FloSports</h1>
      <div className='text-container'>
        <h2>Problem</h2>
        <p>
          FloRugby streams elite rugby matches. While FloSports is the top
          destination for viewing those matches, their website was missing key
          information such as results and standings. This meant fans needed to
          search outside the site to find all of the information they may be
          looking for.
        </p>
        <p>
          Because FloRugby is one of many verticals for FloSports, the product
          team has not prioritzed rugby results or standings.
        </p>
        <h2>Solution</h2>
        <p>
          Working with the Director of Rights Acquisition Operations, I utilized
          a range of tools to automate posting results and standings. The
          results come from a service that provides an API and is passed through
          Zapier where I wrote a small amount of code to transform the data to
          HTML. This is then sent to FloSports’ existing APIs. Fans can now view
          results and standings alongside match replays.
        </p>
        <h2>Outcome</h2>
        <ul>
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.florugby.com/events/7787274/results'
            >
              Results
            </a>
          </li>
          <li>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.florugby.com/rankings/7955834-bunnings-npc-standings/42246-bunnings-npc-2022'
            >
              Standings
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
